<template>
  <section class="user-list">
    
    <div class="page-header">
      <h3 class="page-title">Role List</h3>
      <nav aria-label="breadcrumb">
        <div class="float-right">
          <router-link :to="`/roles/add-role`" class="btn btn btn-add btn-primary">Add New
          </router-link>
        </div>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 mb-5">

        <div class="card">
          <div class="card-title">CMS Role List</div>
          <div class="table-responsive mb-0 pb-3">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Role</th>
                  <th>Tpye</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="roles.cms_roles">
                <tr v-for="(role, index) in roles.cms_roles" v-bind:key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ role }}</td>
                  <td>CMS</td>
                  <td>
                    <router-link class="mr-2 table-icon" v-b-tooltip.hover title="Edit"
                      :to="`/roles/edit-role/${role}?type=cms`">
                      <i class="mdi mdi-pencil "></i>
                    </router-link>
                    <a v-on:click="deleteRole(role, 'cms')" class="table-icon" v-b-tooltip.hover title="Delete">
                      <i class="mdi mdi-delete"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 p-3 text-center" v-if="roles == ''">
            No data found!
          </div>
        </div>
      </div>


      <div class="col-lg-12">


        <div class="card">
          <div class="card-title">Client Role List</div>
          <div class="table-responsive mb-0 pb-3">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Role</th>
                  <th>Tpye</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="roles.client_roles">
                <tr v-for="(role, index) in roles.client_roles" v-bind:key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ role }}</td>
                  <td>Client</td>
                  <td>
                    <router-link class="mr-2 table-icon" v-b-tooltip.hover title="Edit"
                      :to="`/roles/edit-role/${role}?type=client`">
                      <i class="mdi mdi-pencil "></i>
                    </router-link>
                    <a v-on:click="deleteRole(role, 'client')" class="table-icon" v-b-tooltip.hover title="Delete">
                      <i class="mdi mdi-delete"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 p-3 text-center" v-if="roles == ''">
            No data found!
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { roleApi } from "../../api";

export default {
  name: "roles",
  components: {
    
  },
  data() {
    return {
      roles: '',
      
    };
  },
  mounted() {
    this.getPermissions();
  },
  methods: {
    async getPermissions() {
      this.submitted = true;

      
      const { data } = await roleApi.getPermissions();
      this.roles = data.data.data
      
    },
    deleteRole(role, type) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          
          const response = roleApi.deleteRole(role, type).then((response) => {
            this.getPermissions();
            
            if (response.data.code == 200) {
              if (result.value) {
                this.$swal(
                  "Deleted!",
                  "Your file has been deleted.",
                  "success"
                );
              }
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped>

</style>
